import React from "react";
import Buttons from "../components/Buttons";
import Mouse from "../components/Mouse";
import Navigation from "../components/Navigation";
import Project from "../components/Project";

const Project6 = () => {
  return (
    <div>
      <main>
        <Mouse />
        <div className="project">
          <Navigation />
          <Project projectNumber={5} />
          <Buttons left={"/projet-5"} right={"/projet-7"} />
        </div>
      </main>
    </div>
  );
};

export default Project6;