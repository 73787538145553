export const projectsData = [
  {
    id: 1,
    title: "Chambord",
    date: "Juillet 2021",
    languages: ["HTML", "CSS", "JS"],
    infos:
      "Site statique en HTML, CSS et JavaScript pour l’hotel Chambord",
    img: "./assets/img/projet-1.jpg",
    link: "https://chambord.laurentlassallette.com/",
  },
  {
    id: 2,
    title: "Movie",
    date: "Aout 2021",
    languages: ["JS"],
    infos:
      "Site en JavaScript qui permet de voir les bandes-annonces de vos films préférés.",
    img: "./assets/img/projet-2.jpg",
    link: "https://movie.laurentlassallette.com/",
  },
  {
    id: 3,
    title: "Room",
    date: "septembre 2021",
    languages: ["Php", "Bootstrap"],
    infos:
      "Site en Php afin de pouvoir réserver des bureaux dans les grandes villes, possibilité de choisir en rapport avec les tailles et les prix. On peut également s'inscrire ou se connecter avec nom d'utilisateur et mot de passe valide qui est géré avec une expression régulière.",
    img: "./assets/img/projet-3.jpg",
    link: "https://room.laurentlassallette.com/",
  },
  {
    id: 4,
    title: "Green",
    date: "Octobre 2021",
    languages: ["WordPress"],
    infos:
      "Site WooCommerce réalisé avec WordPress. Multiples possibilités. Choix des produits par couleur. Grand choix de produits classé par thème. Navigation facile et bon SEO.",
    img: "./assets/img/projet-4.jpg",
    link: "https://green.laurentlassallette.com/",
  },
  {
    id: 5,
    title: "React Movies",
    date: "Février 2022",
    languages: ["React"],
    infos:
      "Application web crée en React pour rechercher dans une grande base de données grâce à une API tous les films, résumés et leurs notes. Possibilité de classement également.",
    img: "./assets/img/projet-5.jpg",
    link: "https://react-movies.laurentlassallette.com/",
  },
  {
    id: 6,
    title: "Resto",
    date: "Juin 2022",
    languages: ["HTML, SASS"],
    infos:
      "Site vitrine pour un restaurant.",
    img: "./assets/img/projet-6.jpg",
    link: "https://resto.laurentlassallette.com/",
  },
  {
    id: 7,
    title: "Crypto",
    date: "Septembre 2022",
    languages: ["React"],
    infos:
      "Application web crée en React pour rechercher dans une grande base de données grâce à une API toutes les crypto-monnaies. Possibilité de classement également et de voir les graphiques",
    img: "./assets/img/projet-7.jpg",
    link: "https://crypto.laurentlassallette.com/",
  },
];
