import React, { useEffect } from "react";

const SocialNetwork = () => {
  useEffect(() => {
    const icons = document.querySelectorAll(".social-network a");
    icons.forEach((icon) => {
      icon.addEventListener("mouseover", (e) => {
        icon.style.transform = `translate(${e.offsetX - 20}px, ${
          e.offsetY - 13
        }px)`;
      });
      icon.addEventListener("mouseleave", (e) => {
        icon.style.transform = "";
      });
    });
  });
  return (
    <div className="social-network">
      <ul className="content">
        <a
          href="https://www.linkedin.com/in/laurent-front-end/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover"
        >
          <li>
            <i className="fab fa-linkedin"></i>
          </li>
        </a>
        <a
          href="https://github.com/lau-last/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover"
        >
          <li>
            <i className="fab fa-github"></i>
          </li>
        </a>
        <a
          href="https://twitter.com/laurentlast7/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover"
        >
          <li>
            <i className="fab fa-twitter"></i>
          </li>
        </a>
      </ul>
    </div>
  );
};

export default SocialNetwork;
